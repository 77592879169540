export const vorspeiseData = [
  {
    id: 1,
    img: "../images/gao-food1.jpeg",
    name: "Knusprige Reisrollen G,D",
    prep: "Seetangbalätte gefüllt mit Reis and Frischkäse, Avocado und Gurke. Angerichtet mit zwei hausgemachen Saucen.",
    price: "5,90€",
    quantity: 0,
  },
  {
    id: 2,
    img: "../images/gao-food1.jpeg",

    name: "Knusprige Reisrollen B,D,G",
    prep: "Seetangbalätte gefüllt mit Reis, Tofu, eingelegtem Rettich, Frischkäse und Gurke. Angerichtet mit zwei hausgemachen Saucen. ",
    price: "5,50€",
    quantity: 0,
  },
  {
    id: 3,
    img: "../images/gao-food1.jpeg",

    name: "Sommerrollen A,B,L",
    prep: "Reispapier gefüllt mit Nudeln, Salat, Tofu, vietnamesischen Kräutern, Avocado und Ei. Serviert mit hausgemachter Erdnuss-Sauce.",
    price: "5,50€",
    quantity: 1,
  },
  {
    id: 4,
    img: "../images/gao-food1.jpeg",

    name: "Frühlingsrollen A",
    prep: "Knusprige Teigblätter gefüllt mit Tofu, Austernpilzen, Charlotten, Ei und Wasserkastanien. Serviert mit hausgemachte Chili-Sauce",
    price: "5,50€",
    quantity: 0,
  },
  {
    id: 5,
    name: "Wantan gebraten A,G,D",
    prep: "Knusprige Teigblätter gefült mit Tofu, Austernpilzen, Charlotten, Ei und Wasserkastanien. Serviert mit hausgemachter Chili-Sauce.",
    price: "4,50€",
    quantity: 0,
  },
  {
    id: 6,
    name: "Wantan Suppe A,G,D",
    prep: "Hausgemachte Teigblätter gefüllt mit Tofu, Austernpilzen, Charlotten, Ei and Wasserkastanien. Angerichtet in einer kleinen Suppe, abgeschmeckt mit Koriander.",
    price: "4,50€",
    quantity: 0,
  },
  {
    id: 7,
    name: "Tamarind Suppe",
    prep: "Angerichtet mit Tofu, Champignons, Cherry Tomaten, Kräuterseitlingen, Austernpilzen und Lauchzwiebeln in hausgemachter Brühe.",
    price: "4,50€",
    quantity: 0,
  },
  {
    id: 8,
    name: "Seitenspieße D",
    prep: "Zwei knusprig panierte Seitanspieße in einer Hülle aus grünem jungen Klebreis. Serviert mit Salat und hausgemachter Erdnuss Sauce.",
    price: "5,90€",
    quantity: 0,
  },
  // {
  //   id: 9,
  //   name: "Gao Crepes A",
  //   prep: "Dünne Crepes aus Reismehl und Ei, gefüllt mit Tofu, Avocado, Frishkäse, Mango und Gurke. Abgeschmeckt mit vienamesischen Kräutern.",
  //   price: "5,90€",
  //   quantity: 0,
  // },
]
export const hauptgerichteData = [
  {
    id: 10,
    name: "Com Gao A,D",
    prep: "Gebratener Reis mit Ei, abgeschmeckt mit Rotwein. Serviert mit Salat, zwei knusprig panierten Seitanspießen in einer Hülle aus frünem jungen Klebreis.",
    price: "12,90€",
    quantity: 0,
  },
  {
    id: 11,
    name: "Com Gao Omelett A",
    prep: "Knuspriges Reis-Omelett angerichtet auf der heissen Platte. Serviert mit Avocado, Roter Beete, vietnamesischen Kräutern und geschmorten Seitan. Verfeinert mit traditionellen Eigelb-Flocken.",
    price: "10,90€",
    quantity: 0,
  },
  {
    id: 12,
    name: "Omelett B",
    prep: "Vietnamesischer Omelett angerichtet auf der heissen Platte. Serviert mit Frishkäse, Avocado, Rucola, Roter Beete und ungeschmorten Seitan. Abgeschmeckt mit Koriander, Walnüssen und Pinienkernen.",
    price: "10,90€",
    quantity: 0,
  },
  {
    id: 13,
    name: "Gefüllter Tofu",
    prep: "Traditionelles Tofugericht mit Ei, Shiitake, Pilzen, Austernpilzen, Kräuterseitlingen und Wasserkastanien. Serviert mit frischem Mango- und Gurkenstreifen, Als Beilage Cherry Reis Kugeln. ",
    price: "11,90€",
    quantity: 0,
  },
  {
    id: 14,
    name: "Bau Nhoi",
    prep: "Zucchini gefüllt mit Tofu, Ei, Lauch, Austernpilzen, Shiitake Pilzen und Kräuterseitlingen. Abgeschmeckt mit Knoblauch. Als Beilage Cherry Reis Kugel.",
    price: "10,90€",
    quantity: 0,
  },
  {
    id: 15,
    name: "Zucchini-Nudelnsalat B",
    prep: "Reisnudeln mit Zucchini, Karotten und gebratem Seitan, abgeschmeckt mit Zitronengras.Verfeinert mit einer Sauce aus vietnamesischen Kräutern und Erdnuss.",
    price: "10,50€",
    quantity: 0,
  },
  {
    id: 16,
    name: "Long live the Duck B,D",
    prep: "Gebratener Saiten mit traditionellem 'Fünf-Gewürze-Pulver' Ngu Vi Huong. Serviert mit saisonalem Gemüse in hausgemachter Curry-Sauce oder Erdnuss-Sauce. Als Beilage Duftreis.",
    price: "12,90€",
    quantity: 0,
  },
  {
    id: 17,
    name: "Long live the Duck B,D",
    prep: "Knusprig paniertes Seiten in einer Hülle aus grünem jungen Klebreis. Serviert mit saisonalem Gemüse in hausgemachter Curry-Sauce oder Erdnuss-Sauce. Als Beilage Duftreis.",
    price: "10,90€",
    quantity: 0,
  },
  {
    id: 18,
    name: "Scharfe Seitan D",
    prep: "Geschmorrte Seitan mit saisonalem Gemüse, Kräuterseitlingen, Chili, Zuckererbsen, Thai-Basilikum, abgeschmeckt mit Krachaiwurzel (asia.Ingwer). Als Beilage Duftreis.",
    price: "10,90€",
    quantity: 0,
  },
  {
    id: 19,
    name: "Vietnamesisches Ratatouille",
    prep: "Geschmorte Aubergine, Cherry Tomaten und hausgemachter Tofu. Verfeinert mit zwei traditionellen Saucen. Als Beilage Duftreis.",
    price: "9,90€",
    quantity: 0,
  },
]
