import React from "react";
import Layout from "../components/layout";
import { Backdrop, Container } from "../styledComponent/sc";
import gao1 from "../images/food-cut1.png";
import gao2 from "../images/food-cut4.png";
import gao3 from "../images/food-cut3.png";
import blackPic from "../images/pineapple.jpg";
import MenuCard from "../components/card";
import Pdf from "../ultilities/menu.pdf";
import SEO from "../components/seo";
export default function menu() {
  return (
    <Layout>
      <SEO title='MENU 🥗' />

      <Backdrop overlay>
        <div className='backdrop-right'>
          <div className='wrap-content'>
            <h1 style={{ textShadow: "6px 1px black" }}>gạo's Menu</h1>
            <h3>Hungry Much?</h3>

            {/* <h3 className="slogan">Naturally Delicious Vegan Cuisine</h3> */}
            {/* <h3 className="slogan">We serve only the tastiest!</h3> */}
            <p>Natürlich Leckere Vegane Küche</p>
          </div>
          <img src={gao2} alt='A Pineapple' width='400px' height='400px' />
          {/* <h3 className="slogan">Natürlich Leckere Vegane Küche</h3> */}
          <br />
        </div>
      </Backdrop>
      <MenuCard imgUrl={gao1} title='Vegetarische Vorspeise ' menu />
      <MenuCard imgUrl={gao3} title='Vegane Hauptgerichte' />

      <a href={Pdf} target='_blank' className='menu-link'>
        Vollständige Speisekarte Ansehen
      </a>
    </Layout>
  );
}
