import React from "react"
import gaobg from "../images/food-cut1.png"
import { Card, Container } from "../styledComponent/sc"
import { vorspeiseData, hauptgerichteData } from "../ultilities/data"
import Button from "./button"

export default function MenuCard({ imgUrl, title, menu }) {
  return (
    <Card className="menu-card">
      <div className="menu-card-main">
        <h1>{title}</h1>
        <div className="menu-card-main-list">
          {menu
            ? vorspeiseData.map(item => (
                <div className="menu-card-main-item">
                  <div className="menu-card-main-add ">
                    <h5>{item.name}</h5>
                    <span></span>
                    <h5>{item.price}</h5>
                  </div>

                  <p>{item.prep}</p>
                  {/* <Button
                className="addtocard-btn"
                text="+ Add To Cart"
                onClick={addToCart}
              ></Button> */}
                </div>
              ))
            : hauptgerichteData.map(item => (
                <div className="menu-card-main-item">
                  <div className="menu-card-main-add ">
                    <h5>{item.name}</h5>
                    <span></span>
                    <h5>{item.price}</h5>
                  </div>

                  <p>{item.prep}</p>
                  {/* <Button
                className="addtocard-btn"
                text="+ Add To Cart"
                onClick={addToCart}
              ></Button> */}
                </div>
              ))}
        </div>
      </div>
      <div className="menu-wrap-image">
        <img className="menu-card-main-image" src={imgUrl} alt="A pic" />
      </div>
    </Card>
  )
}
